import { IUser } from "../types";

export enum Features {
  ABC_DATA = "ABC Data",
  CALENDAR_REDESIGN = "Calendar Redesign",
  ONBOARDING = "Onboarding",
  PERFORMANCE_REPORT = "Performance Report",
  UTILIZATION = "Utilization",
}

type FeatureFlagRule = {
  clinicIds: string[];
  enabled: boolean;
};

type FeatureFlagConfig = {
  rules: FeatureFlagRule[];
  default: boolean;
};

// Rules are evaluated in order. The first rule match will have the specified
// value returned. If no rules match, "default" is returned
const FEATURE_FLAG_CONFIGS: Record<Features, FeatureFlagConfig> = {
  [Features.ABC_DATA]: {
    rules: [
      {
        clinicIds: ["000"],
        enabled: true,
      },
    ],
    default: false,
  },
  [Features.CALENDAR_REDESIGN]: {
    rules: [
      {
        clinicIds: ["000"],
        enabled: true,
      },
    ],
    default: false,
  },
  [Features.PERFORMANCE_REPORT]: {
    rules: [],
    default: true,
  },
  [Features.UTILIZATION]: {
    rules: [
      {
        clinicIds: ["000"],
        enabled: true,
      },
    ],
    default: false,
  },
  [Features.ONBOARDING]: {
    rules: [{ clinicIds: ["000"], enabled: true }],
    default: false,
  },
};

export function evaluateFlag<TFeature extends Features>(feature: TFeature, user?: IUser) {
  const config = FEATURE_FLAG_CONFIGS[feature];
  for (const rule of config.rules) {
    if (rule.clinicIds.includes(user?.clinicId || "")) {
      return rule.enabled;
    }
  }
  return config.default;
}
