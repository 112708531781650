//formats
export const TIME_FORMAT = "HH:mm:ss Z";
export const TIME_DISPLAY_FORMAT = "h:mm A";
export const DATE_FORMAT = "YYYY-MM-DD";
export const DISPLAY_DATE_FORMAT = "YYYY MMM. DD";
export const DISPLAY_DATETIME_FORMAT = "YYYY MMM. DD [at] h:mm A";

//regex
export const VALID_EMAIL_REGEX =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const VALID_PASSWORD_REGEX =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#$%^&*-_=+;:'",<.>?])[A-Za-z\d`~!@#$%^&*-_=+;:'",<.>?]{8,}$/;
export const VALID_HEALTH_CARD_REGEX = /^\d{10}[a-zA-Z]{2}$/;
export const VALID_PHONE_NUMBER_REGEX = /^(\+?\d{1,2}[\s.-]?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const VALID_ZIP_CODE_REGEX = /^\d{5}(-\d{4})?$/;

// Misc.
export const enum InquiryStates {
  ENTER_ZIP_CODE,
  ENTER_GUARDIAN_STATUS,
  ENTER_DIAGNOSES,
  CONTACT_FORM,
  SUBMITTED,
  UNSUPPORTED_ZIP_CODE,
  UNSUPPORTED_GUARDIAN_STATUS,
  UNSUPPORTED_DIAGNOSES,
}

export const enum ClientFileProviderStates {
  SUBMIT_DOCUMENTS,
}

export enum PageName {
  CLIENT_FILE_FLOW = "Client File Flow",
  CONFIRM_INSURANCE = "Confirm Insurance",
  HOME = "Home",
  INSURANCE_CONFIRMED = "Insurance Confirmed",
  INQUIRE_COMPLETE = "Inquire Complete",
  INQUIRY_FLOW = "Inquiry Flow",
  LOGIN = "Login",
  PREAPPROVAL = "Preapproval",
  SCHEDULE = "Schedule",
  SECRET_SIGNUP = "Secret Signup",
  SET_PASSWORD = "Set Password",

  //null
  NULL = "Null",
  NOT_FOUND = "Not Found",
}

export enum ClientOnboardingFlow {
  CLIENT_DETAILS = "Client Details",
  CONSENT_FORM = "Consent Form",
  CONTACT_FORM = "Contact Form",
  INTAKE_COMPLETE = "Intake Complete",
  INTAKE_QUESTIONNAIRE = "Intake Questionnaire",
  PAYERS = "Payers",
  PREAPPROVAL_COMPLETE = "Preapproval Complete",
  PREAPPROVAL_TASKS_CARD = "Preapproval Tasks Card",
  PROVIDERS = "Providers",
  TASKS_CARD_TASK = "Tasks Card Task",
  WELCOME = "Welcome",
}

export const enum ClientFileIntakeQuestionnaireStates {
  //TherapyPreferences
  ENTER_THERAPY_LOCATION,
  ENTER_PRIMARY_LANGUAGE,
  ENTER_PREFERRED_LANGUAGE,
  ENTER_PREFERRED_THERAPIST_GENDER,
  ENTER_OTHER_CAREGIVER,
  ENTER_AVAILABILITY,
  //StrengthsAndChallenges
  ENTER_SELF_HARM_FREQUENCY,
  ENTER_AGGRESSION_FREQUENCY,
  ENTER_RUNNING_AWAY_FREQUENCY,
  ENTER_DESTROYING_PROPERTY_FREQUENCY,
  ENTER_FALLING_DOWN_FREQUENCY,
  ENTER_STRIPPING_CLOTHING_FREQUENCY,
  ENTER_GOALS,
  //MedicalHistory
  ENTER_CURRENT_SERVICES,
  ENTER_PREVIOUS_SERVICES,
  ENTER_LIVING_SITUATION,
  ENTER_MEDICAL_CONDITIONS,
  ENTER_PRESCRIBED_MEDICATION,
  ENTER_FAMILY_MEDICAL_HISTORY,
  ENTER_OTHER_ISSUES,
  ENTER_VISION_HEARING_ABILITY,
}

export const ROW_MAX_WIDTH = 1000;

export const selected = {
  height: 44,
  width: 150,
  color: "#fa9a5a",
  borderColor: "#fa9a5a",
};

export const unselected = {
  height: 44,
  width: 150,
};

export const selectedToggle = {
  height: 44,
  width: 400,
  color: "#fa9a5a",
  borderColor: "#fa9a5a",
};

export const unselectedToggle = {
  height: 44,
  width: 400,
  color: "rgba(0, 0, 0, 0.65)",
  borderColor: "#dadada",
};

export const frequencyMarks = {
  0: "Monthly",
  1: "Weekly",
  2: "Daily",
  3: "Hourly",
  4: "Constantly",
};
