import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { IUser, UserPermission } from "../types";

const c = initContract();

export const usersContract = c.router({
  updateUserPermission: {
    method: "POST",
    path: "/users/update-permission",
    body: z.object({
      id: z.string(),
      permission: z.nativeEnum(UserPermission),
    }),
    responses: {
      200: z.string(),
      400: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  getUserByEmail: {
    method: "GET",
    path: "/users/get-by-email",
    query: z.object({
      email: z.string(),
    }),
    responses: {
      200: c.type<{ user: IUser }>(),
      404: z.object({
        message: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
