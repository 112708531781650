import { z } from "zod";

import {
  addressSchema,
  CredentialSchema,
  IAddress,
  ISchedule,
  IStaffFile,
  IUser,
  OrbitPermission,
  ProviderType,
  ScheduleSchema,
  Sex,
  StaffFileSchema,
  UserPermission,
  UserStatsSchema,
} from "../types";
import { TransportMode } from "./google-maps";

export interface IGetUserByIdEndpointRequest {
  id: string;
}

export interface IGetUserByEmailEndpointRequest {
  email: string;
}

export interface ICreateUserEndpointRequest {
  clinicId: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  documents: IStaffFile[];
  phoneNumber: string;
  // null is used to control the new clinic signup step
  providerType: ProviderType | null;
  orbitPermissions: OrbitPermission[];
  permissions: UserPermission[];
  address: IAddress;
  addressNotes?: string;
  sex: Sex;
  dateOfBirth: string;
  isFullTime: boolean;
  npi: string;
  schedule?: ISchedule;
  hasSignedUp: boolean;
  preferredTransport: TransportMode;
  allowedClinicIds: string[];
  npsSurveyCompletedAt?: null | string;
}

export interface IUpdateUserOrbitPermissionsEndpointRequest {
  userId: string;
  orbitPermissions: OrbitPermission[];
}

export interface ISignJWTRequest {
  id: string;
  redirectUri: string;
}

export interface IUpdateUserClinicIdEndpointRequest {
  clinicId: string;
}

export interface IDeleteUserEndpointRequest {
  id: string;
}

export type IUpdateUserEndpointRequest = Partial<IUser> & Pick<IUser, "id">;

export interface ICalculateUserStatsEndpointRequest {
  id: string;
}

export interface IUpdateUserNPSSurveyCompletedEndpointRequest {
  id: string;
  npsSurveyCompletedAt: string | null;
}

// Define Zod schema for the IUser interface
export const UserSchema = z.object({
  id: z.string(),
  motivityUUID: z.string().optional(),
  sandataStaffId: z.string().optional(),
  slackId: z.string().optional(),
  clinicId: z.string(),
  allowedClinicIds: z.array(z.string()).optional(),
  firstName: z.string(),
  middleName: z.string().optional(),
  lastName: z.string(),
  email: z.string(),
  phoneNumber: z.string(),
  sex: z.nativeEnum(Sex),
  dateOfBirth: z.string(),
  isFullTime: z.boolean(),
  providerType: z.nativeEnum(ProviderType),
  permissions: z.array(z.nativeEnum(UserPermission)),
  npi: z.string().optional(),
  credentials: z.array(CredentialSchema).optional(),
  documents: z.array(StaffFileSchema).optional(),
  orbitPermissions: z.array(z.nativeEnum(OrbitPermission)).optional(),
  startDateMs: z.number().optional(),
  hasSetSchedule: z.boolean().optional(),
  hasSignedUp: z.boolean().optional(),
  schedule: ScheduleSchema.optional(),
  stats: UserStatsSchema.optional(),
  address: addressSchema.optional(),
  addressNotes: z.string(),
  preferredTransport: z.nativeEnum(TransportMode),
  npsSurveyCompletedAt: z.string().datetime().nullable().optional(),
});
