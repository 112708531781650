import {
  DB_DATE_FORMAT,
  DISPLAY_DATE_FORMAT,
  IClient,
  IClientFile,
  IUpdateClientEndpointRequest,
  PayerVerificationType,
} from "@finni-health/shared";
import { useMutation } from "@tanstack/react-query";
import { Button, Card, Col, DatePicker, Form, Input, message, Row, Typography } from "antd";
import { getDownloadURL } from "firebase/storage";
import moment from "moment";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { moveObject } from "../../helpers/storage";
import * as FirestoreService from "../../services/firestore";
import { InsurancePayerSelect } from "./Form/InsurancePayerSelect";
import { NewInsuranceFormItem } from "./Form/NewInsuranceFormItem";
import { InsuranceFileUpload } from "./InsuranceFileUpload";

const { Title } = Typography;

interface INewInsuranceFormValues {
  payerId: string;
  memberId: string;
  groupNumber: string;
  frontFileUrl: string;
  backFileUrl: string;
  startDate: moment.Moment;
}

export const NewInsuranceCard = ({
  client,
  clientFile,
}: {
  client: IClient;
  clientFile: IClientFile;
}) => {
  const history = useHistory();
  const [form] = Form.useForm<INewInsuranceFormValues>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialStartDate =
    moment.now() < moment("01-01-2025").startOf("day").valueOf() ? moment("01-01-2025") : moment();

  const updateClientMutation = useMutation({
    mutationFn: (newPayers: IUpdateClientEndpointRequest["payers"]) =>
      FirestoreService.updateClient({
        id: client.id,
        payers: newPayers,
      }),
  });

  const moveStagedFile = async (fileUrl: string, storagePath: string): Promise<string> => {
    const ref = await moveObject(fileUrl, storagePath);
    return getDownloadURL(ref);
  };

  const onSubmit = async (values: INewInsuranceFormValues) => {
    setIsSubmitting(true);

    try {
      const frontFileUrl = await moveStagedFile(
        values.frontFileUrl,
        `clientFiles/${clientFile.id}/insurance/primary/front`
      );
      const backFileUrl = await moveStagedFile(
        values.backFileUrl,
        `clientFiles/${clientFile.id}/insurance/primary/back`
      );

      const payerType =
        values.startDate.startOf("day").valueOf() > moment().startOf("day").valueOf()
          ? PayerVerificationType.UPCOMING_PRIMARY
          : PayerVerificationType.PRIMARY;

      await updateClientMutation.mutateAsync({
        ...client.payers,
        [payerType]: {
          payerId: values.payerId,
          memberNum: values.memberId,
          groupNum: values.groupNumber,
          photoUrls: [frontFileUrl, backFileUrl],
          startDate: values.startDate.format(DB_DATE_FORMAT),
        },
      });
      history.push("/insurance-confirmed");
    } catch (error) {
      console.error(error);
      void message.error("Failed to confirm insurance information");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card
      style={{
        flex: 1,
        maxWidth: 350,
        borderRadius: 10,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
      }}
      title={
        <Row align="middle" justify="center">
          <Title level={5} style={{ marginTop: 5, marginBottom: 5 }}>
            New Insurance Card
          </Title>
        </Row>
      }
    >
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Row justify="center" align="middle" style={{ padding: 10, width: "100%" }}>
          <Col
            span={24}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
            }}
          >
            <NewInsuranceFormItem name="frontFileUrl" style={{ margin: 0 }}>
              <InsuranceFileUpload
                label="Front"
                name="front"
                isEditable={true}
                // staged location to not overwrite the current insurance pictures
                storagePath="primary/front-staged"
              />
            </NewInsuranceFormItem>
            <NewInsuranceFormItem name="backFileUrl" style={{ margin: "0 0 24px 0" }}>
              <InsuranceFileUpload
                label="Back"
                name="back"
                isEditable={true}
                // staged location to not overwrite the current insurance pictures
                storagePath="primary/back-staged"
              />
            </NewInsuranceFormItem>
            <NewInsuranceFormItem name="payerId" label="Payer">
              <InsurancePayerSelect onChange={(payerId) => form.setFieldValue("payer", payerId)} />
            </NewInsuranceFormItem>
            <NewInsuranceFormItem name="memberId" label="Member ID">
              <Input placeholder="Member ID" />
            </NewInsuranceFormItem>
            <NewInsuranceFormItem name="groupNumber" label="Group Number">
              <Input placeholder="Group number" />
            </NewInsuranceFormItem>
            <NewInsuranceFormItem
              label="Start Date"
              name="startDate"
              initialValue={initialStartDate}
            >
              <DatePicker
                format={DISPLAY_DATE_FORMAT}
                disabledDate={(current) =>
                  current && current.startOf("day") < moment().startOf("day")
                }
                disabled={isSubmitting}
              />
            </NewInsuranceFormItem>
          </Col>

          <Button
            size="large"
            type="primary"
            shape="round"
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={() => form.submit()}
          >
            This is my new insurance info
          </Button>
        </Row>
      </Form>
    </Card>
  );
};
