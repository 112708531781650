import { z } from "zod";

// API Docs: https://apidocs.tryadonis.com/
// Note: these docs are inconsistent / out of date at times so take with a grain of salt

export const adonisBillingAddressSchema = z.object({
  address_1: z.string(),
  address_2: z.string().optional(),
  city: z.string(),
  state: z.string(),
  zip_code: z.string(),
  zip_plus_four_code: z.string().optional(),
});

export type IAdonisBillingAddress = z.input<typeof adonisBillingAddressSchema>;

export const adonisServiceFacilityInfoSchema = z.object({
  organization_name: z.string().optional(),
  npi: z.string().optional(),
  address: adonisBillingAddressSchema,
});

export type IAdonisServiceFacilityInfo = z.input<typeof adonisServiceFacilityInfoSchema>;

export enum AdonisPlanType {
  MEDICAID = "MC",
  COMMERCIAL = "CI",
  TRICARE = "CH",
  BLUE_CROSS_BLUE_SHIELD = "BL",
  OTHER = "11",
}

export enum AdonisRelationshipToSubscriberCode {
  SELF = "18",
  CHILD = "19",
}

export enum AdonisGender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

export const adonisInsuranceSchema = z.object({
  adonis_payer_id: z.string(),
  payer_id: z.string().optional(),
  payer_name: z.string().optional(),
  plan_name: z.string().optional(),
  insurance_type: z.nativeEnum(AdonisPlanType).optional(),
  member_id: z.string(),
  eligibility_admin_payer_id: z.nativeEnum(AdonisRelationshipToSubscriberCode).optional(),
  group_number: z.string(),
  relationship_to_subscriber_code: z.nativeEnum(AdonisRelationshipToSubscriberCode).optional(),
  subscriber: z.object({
    first_name: z.string(),
    last_name: z.string(),
    gender: z.nativeEnum(AdonisGender).optional(),
    middle_name: z.string().optional(),
    suffix: z.string().optional(),
    phone_number: z.string().optional(),
    address: adonisBillingAddressSchema.optional(),
    date_of_birth: z.string().optional(),
  }),
});

export type IAdonisInsurance = z.input<typeof adonisInsuranceSchema>;

export const adonisProviderRefSchema = z.object({
  provider_id: z.string().optional(),
  npi: z.string().optional(),
  external_provider_id: z.string().optional(),
});

export type IAdonisProviderRef = z.input<typeof adonisProviderRefSchema>;

export const adonisReferringProviderSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  taxonomy_code: z.string(),
  npi: z.string(),
});

export type IAdonisReferringProvider = z.input<typeof adonisReferringProviderSchema>;

export const adonisFacilitySchema = z.object({
  external_facility_id: z.string().optional(),
  display_name: z.string(),
  organization_name: z.string(),
  npi: z.string(),
  address: adonisBillingAddressSchema,
});

export type IAdonisFacility = z.input<typeof adonisFacilitySchema>;

export const adonisServiceLineSchema = z.object({
  date_of_service_start: z.string(), // format YYYY-MM-DD
  date_of_service_end: z.string().optional(), // format YYYY-MM-DD
  place_of_service_code: z.string(),
  cpt_hcpcs_code: z.string(),
  quantity: z.number(),
  quantity_type: z.string(),
  icd10_codes: z.array(z.string()),
  modifiers: z.array(z.string()),
  charge_amount: z.string(), // dollars
  description: z.string().optional(),
});

export type IAdonisServiceLine = z.input<typeof adonisServiceLineSchema>;

export enum AdonisClaimStatus {
  ESCALATED_TO_PROVIDER = "ESCALATED_TO_PROVIDER",
  ESCALATED_TO_MANAGER = "ESCALATED_TO_MANAGER",
  SUBMITTED_TO_PAYER = "SUBMITTED_TO_PAYER",
  ACKNOWLEDGED = "ACKNOWLEDGED",
  ERA_REVIEW = "ERA_REVIEW",
  REJECTED = "REJECTED",
  DENIED = "DENIED",
  APPEALED = "APPEALED",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  FINALIZED_PAID = "FINALIZED_PAID",
  VOIDED = "VOIDED",
}

export const adonisClaimSchema = z.object({
  external_encounter_id: z.string(),
  patient: z.object({
    patient_id: z.string().optional(),
    external_patient_id: z.string(),
  }),
  service_facility: z.object({
    service_facility_id: z.string(),
    external_service_facility_id: z.string().nullish(),
  }),
  service_facility_info: adonisServiceFacilityInfoSchema.optional(),
  primary_patient_insurance: adonisInsuranceSchema,
  secondary_patient_insurance: adonisInsuranceSchema.optional(),
  rendering_provider: adonisProviderRefSchema.optional(),
  supervising_provider: adonisProviderRefSchema.optional(),
  billing_provider_id: z.string().optional(),
  referring_provider: adonisReferringProviderSchema.optional(),
  prior_authorization_number: z.string().optional(),
  referral_number: z.string().optional(),
  provider_notes: z.string().optional(),
  icd10_codes: z.array(z.string()),
  service_lines: z.array(adonisServiceLineSchema),
  // other_claim_id?: string;
  // related_to_auto_accident?: boolean;
  // related_to_employment?: boolean;
  // related_to_other_accident?: boolean;
  onset_date_type: z.string().optional(),
  onset_date_value: z.string().optional(),
  // other_date_type
  // other_date_value
  // benefits_assigned
  // provider_accepts_assignment
  additional_information: z.string(),
  status: z.nativeEnum(AdonisClaimStatus).optional(), // included in claim return
});

export type IAdonisClaim = z.input<typeof adonisClaimSchema>;

export const adonisClientSchema = z.object({
  last_name: z.string(),
  first_name: z.string(),
  middle_name: z.string().optional(),
  date_of_birth: z.string(),
  gender: z.nativeEnum(AdonisGender).optional(),
  external_patient_id: z.string(), // FKey client.id
  address: adonisBillingAddressSchema,
  suffix: z.string().optional(),
  hold: z.boolean().optional(),
  custom_fields: z.record(z.string(), z.string()).optional(),
  phone_number: z.string().optional(),
});

export type IAdonisClient = z.input<typeof adonisClientSchema>;

export enum AdonisProviderStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export const adonisProviderSchema = z.object({
  external_provider_id: z.string(), // FKey provider.id
  last_name: z.string(),
  first_name: z.string(),
  middle_name: z.string().optional(),
  suffix: z.string().optional(),
  gender: z.nativeEnum(AdonisGender).optional(),
  display_name: z.string().optional(),
  npi: z.string(),
  status: z.nativeEnum(AdonisProviderStatus),
  taxonomy_code: z.string(),
  provider_type: z.string(),
  default_supervising_provider: adonisProviderRefSchema.optional(),
  phone_number: z.string().optional(),
  employment_start_date: z.string().optional(), // format YYYY-MM-DD
  employment_end_date: z.string().optional(), // format YYYY-MM-DD
  hold_date: z.string().optional(), // format YYYY-MM-DD
  // contract_assignments
  // licenses
  // identifiers
});

export type IAdonisProvider = z.input<typeof adonisProviderSchema>;
