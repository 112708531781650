import {
  AppointmentType,
  BillingCode,
  IAppointmentConfig,
  IAppointmentTypeConfig,
  Modifier,
  USStateCode,
} from "../../types";
import { ALABAMA_APPOINTMENTS } from "./alabama";
import { ARIZONA_APPOINTMENTS } from "./arizona";
import { CALIFORNIA_APPOINTMENTS } from "./california";
import { COLORADO_APPOINTMENTS } from "./colorado";
import { CONNECTICUT_APPOINTMENTS } from "./connecticut";
import { GEORGIA_APPOINTMENTS } from "./georgia";
import { ILLINOIS_APPOINTMENTS } from "./illinois";
import { KENTUCKY_APPOINTMENTS } from "./kentucky";
import { MICHIGAN_APPOINTMENTS } from "./michigan";
import { NEW_HAMPSHIRE_APPOINTMENTS } from "./newHampshire";
import { NEW_MEXICO_APPOINTMENTS } from "./newMexico";
import { NEW_YORK_APPOINTMENTS } from "./newYork";
import { OHIO_APPOINTMENTS } from "./ohio";
import { SOUTH_CAROLINA_APPOINTMENTS } from "./southCarolina";
import { TENNESSEE_APPOINTMENTS } from "./tennessee";
import { VERMONT_APPOINTMENTS } from "./vermont";
import { VIRGINIA_APPOINTMENTS } from "./virginia";
import { WASHINGTON_APPOINTMENTS } from "./washington";

export const BASE_APPOINTMENTS: IAppointmentConfig[] = [
  {
    name: AppointmentType.INITIAL_ASSESSMENT,
    billingCode: BillingCode.CODE_97151,
    modifiers: [],
  },
  {
    name: AppointmentType.ADDITIONAL_ASSESSMENT,
    billingCode: BillingCode.CODE_97152,
    modifiers: [],
  },
  {
    name: AppointmentType.DIRECT_SERVICES,
    billingCode: BillingCode.CODE_97153,
    modifiers: [],
  },
  {
    name: AppointmentType.DIRECT_SUPERVISION,
    billingCode: BillingCode.CODE_97155,
    modifiers: [],
  },
  {
    name: AppointmentType.PARENT_TRAINING,
    billingCode: BillingCode.CODE_97156,
    modifiers: [],
  },
  {
    name: AppointmentType.PARENT_TRAINING_PATIENT_PRESENT,
    billingCode: BillingCode.CODE_97156,
    modifiers: [Modifier.HS],
  },
  {
    name: AppointmentType.PARENT_TRAINING_PATIENT_NOT_PRESENT,
    billingCode: BillingCode.CODE_97156,
    modifiers: [Modifier.HR],
  },
];

export const STATE_APPOINTMENTS: IAppointmentTypeConfig = {
  [USStateCode.CO]: COLORADO_APPOINTMENTS,
  [USStateCode.CT]: CONNECTICUT_APPOINTMENTS,
  [USStateCode.GA]: GEORGIA_APPOINTMENTS,
  [USStateCode.IL]: ILLINOIS_APPOINTMENTS,
  [USStateCode.MI]: MICHIGAN_APPOINTMENTS,
  [USStateCode.NM]: NEW_MEXICO_APPOINTMENTS,
  [USStateCode.NY]: NEW_YORK_APPOINTMENTS,
  [USStateCode.OH]: OHIO_APPOINTMENTS,
  [USStateCode.VA]: VIRGINIA_APPOINTMENTS,
  [USStateCode.WA]: WASHINGTON_APPOINTMENTS,
  [USStateCode.AL]: ALABAMA_APPOINTMENTS,
  [USStateCode.AZ]: ARIZONA_APPOINTMENTS,
  [USStateCode.SC]: SOUTH_CAROLINA_APPOINTMENTS,
  [USStateCode.CA]: CALIFORNIA_APPOINTMENTS,
  [USStateCode.KY]: KENTUCKY_APPOINTMENTS,
  [USStateCode.TN]: TENNESSEE_APPOINTMENTS,
  [USStateCode.NH]: NEW_HAMPSHIRE_APPOINTMENTS,
  [USStateCode.VT]: VERMONT_APPOINTMENTS,
};
