import { DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import {
  DEBUG_DROPBOX,
  DROPBOX_CONTRACTS,
  DropboxDocumentSignerRole,
  DropboxDocumentType,
  IClient,
  IClientFile,
  ISignedDropboxDocument,
} from "@finni-health/shared";
import { Button, Card, Divider, List, message, Row, Typography } from "antd";
import * as HelloSign from "hellosign-embedded";
import { useContext, useEffect, useRef, useState } from "react";

import * as FirestoreService from "../../services/firestore";
import { AuthContext } from "../AuthProvider";

// Hack the types for the library
const dropboxClient = new (HelloSign as any)({
  clientId: process.env.REACT_APP_DROPBOX_CLIENT_ID,
});

const { Title, Text } = Typography;
export interface IProps {
  clientFile: IClientFile;
  client: IClient;
  refreshClientFile: () => void;
}

export const ConsentFormsTask = ({ clientFile, client, refreshClientFile }: IProps) => {
  const { guardian, clinic } = useContext(AuthContext);

  const [signedDocuments, setSignedDocuments] = useState<ISignedDropboxDocument[]>(
    clientFile.signedDocuments || []
  );
  const [upNextDocuments, setUpNextDocuments] = useState<DropboxDocumentType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const retriesRef = useRef<number>(0); // avoid re-renders
  const currentDocBeingSignedRef = useRef<DropboxDocumentType | undefined>(); // avoid re-renders

  const handleDownloadDocument = async (document: ISignedDropboxDocument) => {
    const signatureRequestId = document.signatureRequestId;

    try {
      const pdf = await FirestoreService.downloadSignedDocument({
        signatureRequestId,
      });

      window.open(pdf.fileUrl, "_blank");
    } catch (error) {
      console.error("error", error);
      void message.error(
        "Oops! Something went wrong. Please try again or reach out to care@finnihealth.com for help"
      );
    }
  };

  const handleGetEmbbedUrl = async (documentType: DropboxDocumentType) => {
    try {
      currentDocBeingSignedRef.current = documentType;
      const currentForm = DROPBOX_CONTRACTS[documentType];

      const formData = {
        signers: [
          {
            emailAddress: guardian.email,
            name: guardian.firstName + " " + guardian.lastName,
            role: DropboxDocumentSignerRole["Parent/Guardian"],
          },
        ],
        formName: documentType,
        customFields: [
          {
            name: currentForm.customFields.parentFullName,
            value: guardian.firstName + " " + guardian.lastName,
            editor: DropboxDocumentSignerRole["Parent/Guardian"],
            required: true,
          },
        ],
      };

      // Prefill fields if it's the general consent form
      if (documentType === DropboxDocumentType.CAREGIVER_CONSENT_FORM) {
        formData.customFields = [
          ...formData.customFields,
          {
            name: currentForm.customFields.clientFullName,
            value: client?.firstName + " " + client?.lastName,
            editor: DropboxDocumentSignerRole["Parent/Guardian"],
            required: true,
          },
          {
            name: currentForm.customFields.practiceName,
            value: clinic.displayName,
            editor: DropboxDocumentSignerRole["Parent/Guardian"],
            required: true,
          },
        ];
      }

      const embedResponse = await FirestoreService.getEmbeddedUrl(formData);

      if (embedResponse.signUrl && embedResponse.expiresAt) {
        openEmbedWindow(embedResponse.signUrl);
      } else {
        currentDocBeingSignedRef.current = undefined;

        void message.error(
          "Oops! Something went wrong. Please try again or reach out to care@finnihealth.com for help"
        );
      }
    } catch (error) {
      currentDocBeingSignedRef.current = undefined;

      console.error(error);
      void message.error(
        "Oops! Something went wrong. Please try again or reach out to care@finnihealth.com for help"
      );
    }
  };

  const openEmbedWindow = (url: string) => {
    dropboxClient.open(url, {
      clientId: process.env.REACT_APP_DROPBOX_CLIENT_ID,
      allowCancel: true,
      // Set these both to true when testing
      skipDomainVerification: DEBUG_DROPBOX,
      testMode: DEBUG_DROPBOX,
    });
  };

  const updateUpNextDocuments = () => {
    setUpNextDocuments(
      Object.values(DropboxDocumentType).filter(
        (documentType) =>
          documentType !== DropboxDocumentType.MEDIA_CONSENT_FORM && // This is bunched with the general consent form
          !signedDocuments.map((document) => document.documentType).includes(documentType)
      )
    );
  };

  useEffect(() => {
    updateUpNextDocuments();
  }, [signedDocuments, clientFile]);

  useEffect(() => {
    if (clientFile.signedDocuments !== signedDocuments) {
      setSignedDocuments(clientFile.signedDocuments || []);
      setIsLoading(false);
    }
  }, [clientFile]);

  // Listen for the finish event so we know when the document has been signed
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const handleFinish = (): void => {
      setIsLoading(true);

      const checkAndUpdateClientFile = async () => {
        const freshClientFile = await FirestoreService.getClientFileByGuardianId(guardian.id);

        const formHasBeenSigned = freshClientFile.signedDocuments?.find(
          (doc: ISignedDropboxDocument) => doc.documentType === currentDocBeingSignedRef.current
        );

        if (formHasBeenSigned) {
          refreshClientFile();
          clearInterval(intervalId);
          currentDocBeingSignedRef.current = undefined;

          return;
        }

        if (retriesRef.current >= 10) {
          console.info("Exceeded max retries, please refresh the page");
          refreshClientFile();
          clearInterval(intervalId);
          currentDocBeingSignedRef.current = undefined;

          void message.error("Exceeded max retries, please refresh the page");
          return;
        }

        retriesRef.current += 1;
      };

      intervalId = setInterval(checkAndUpdateClientFile, 4000);
    };

    dropboxClient.on("finish", handleFinish);

    return () => {
      dropboxClient.off("finish", handleFinish);
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Card
      style={{
        width: "100%",
        margin: "0 10px",
        whiteSpace: "pre-wrap",
      }}
      title={
        <Row align="middle" justify="space-between">
          <Title level={4} style={{ marginTop: 5, marginBottom: 5 }}>
            Handbooks and Consent Forms
          </Title>
        </Row>
      }
    >
      <Text
        style={{
          whiteSpace: "pre-wrap",
        }}
      >
        Download a copy for your records.
      </Text>
      <Row style={{ width: "100%", marginTop: 5 }}>
        <List
          size="small"
          style={{ width: "100%" }}
          bordered
          dataSource={signedDocuments}
          renderItem={(document: ISignedDropboxDocument) => (
            <List.Item
              actions={[
                <Button
                  key={document.documentType}
                  type="text"
                  onClick={() => handleDownloadDocument(document)}
                >
                  <DownloadOutlined />
                </Button>,
              ]}
            >
              <Row>{document.documentType}</Row>
            </List.Item>
          )}
        />
      </Row>

      {upNextDocuments.length > 0 && (
        <>
          <Divider />
          <Title level={4} style={{ marginTop: 5, marginBottom: 5 }}>
            Other Consent Forms
          </Title>
          <Row style={{ width: "100%", marginTop: 5 }}>
            <List
              size="small"
              style={{ width: "100%", marginTop: 5 }}
              bordered
              dataSource={upNextDocuments}
              renderItem={(documentType: DropboxDocumentType) => (
                <List.Item
                  actions={[
                    <Button
                      key={documentType}
                      type="text"
                      onClick={() => handleGetEmbbedUrl(documentType)}
                      loading={
                        currentDocBeingSignedRef.current === documentType ||
                        (isLoading && currentDocBeingSignedRef.current === documentType)
                      }
                    >
                      <FilePdfOutlined />
                    </Button>,
                  ]}
                >
                  <Row>{documentType}</Row>
                </List.Item>
              )}
            />
          </Row>
        </>
      )}
    </Card>
  );
};
