import { ArrowLeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { IClientFile } from "@finni-health/shared";
import { useResponsive } from "@finni-health/ui";
import { Button, Col, Modal, Row, Steps, Typography } from "antd";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { PageName } from "../../consts";
import { getRemainingPreapprovalStepsCount } from "../../helpers/client-file-status";
import { usePageViewTracking } from "../../hooks/usePageViewTracking";
import { Loading } from "../../pages/Loading";
import * as FirestoreService from "../../services/firestore";
import { AuthContext } from "../AuthProvider";
import { ConsentForm } from "../InquiryFlow/ConsentForm";
import { ClientDetails } from "./ClientDetails";
import { Payers } from "./Payers";
import { Providers } from "./Providers";

const { Text } = Typography;
const { confirm } = Modal;

export enum PREAPPROVAL_TIMELINE_STEPS {
  CLIENT_DETAILS,
  CARE_DOCUMENTS,
  INSURANCE,
  CONSENT_FORM,
}

export const PREAPPROVAL_STEP_NAMES = {
  CLIENT_DETAILS: "Child Details",
  CARE_DOCUMENTS: "Care Documents",
  INSURANCE: "Insurance",
  CONSENT_FORM: "Consent Form",
};

export const Preapproval: React.FC = () => {
  const { guardian } = useContext(AuthContext);
  const history = useHistory();
  const { isMobile } = useResponsive();

  const [remainingSteps, setRemainingSteps] = useState<number>(-1);
  const [currTimelineStep, setCurrTimelineStep] = useState<PREAPPROVAL_TIMELINE_STEPS>();
  const [clientFile, setClientFile] = useState<IClientFile>({} as IClientFile);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  usePageViewTracking(PageName.PREAPPROVAL);

  useEffect(() => {
    fetchState().catch(() => {});
  }, [guardian]);

  useEffect(() => {
    getClientFileProgress(clientFile);
  }, [clientFile]);

  const fetchState = async () => {
    if (!_.isEmpty(guardian)) {
      const clientFile = await FirestoreService.getClientFileByGuardianId(guardian.id);
      getClientFileProgress(clientFile);
      setClientFile(clientFile);
      setIsLoading(false);
    }
  };

  const getClientFileProgress = (clientFile: IClientFile) => {
    const remainingSteps = getRemainingPreapprovalStepsCount(clientFile);
    const timelineStep = (4 - remainingSteps) as PREAPPROVAL_TIMELINE_STEPS;

    if (remainingSteps === 0) {
      history.push("/");
    }

    setRemainingSteps(remainingSteps);
    setCurrTimelineStep(timelineStep);
  };

  const confirmExit = () => {
    confirm({
      title: "Are you sure you want to exit?",
      icon: <ExclamationCircleOutlined />,
      content: "Your progress will not be saved until you complete the current step.",
      okText: "Yes",
      onOk() {
        history.push("/");
      },
    });
  };

  return isLoading ? (
    <Loading pageName={PageName.PREAPPROVAL} />
  ) : (
    <>
      <Row style={{ width: "100%" }} justify="center" align="top">
        <Button
          style={{
            position: "absolute",
            left: isMobile ? 15 : 20,
            marginTop: isMobile ? 8 : -15,
            height: 40,
          }}
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={confirmExit}
        >
          {!isMobile && "Exit"}
        </Button>

        {isMobile ? (
          <Text style={{ display: "block", marginTop: 15, fontWeight: 500 }}>
            {`Task ${5 - remainingSteps}/4 ${
              PREAPPROVAL_STEP_NAMES[
                PREAPPROVAL_TIMELINE_STEPS[
                  4 - remainingSteps
                ] as keyof typeof PREAPPROVAL_STEP_NAMES
              ]
            }`}
          </Text>
        ) : (
          <Steps
            progressDot
            current={4 - remainingSteps}
            direction="horizontal"
            style={{ width: "80%" }}
          >
            {Object.values(PREAPPROVAL_STEP_NAMES).map((stepName) => (
              <Steps.Step key={stepName} subTitle={stepName} />
            ))}
          </Steps>
        )}
      </Row>
      <Row style={{ width: "100%" }} justify="center">
        <Col span={isMobile ? 24 : undefined}>
          {currTimelineStep === PREAPPROVAL_TIMELINE_STEPS.CLIENT_DETAILS && (
            <ClientDetails clientFile={clientFile} setClientFile={setClientFile} />
          )}
          {currTimelineStep === PREAPPROVAL_TIMELINE_STEPS.CARE_DOCUMENTS && (
            <Providers clientFile={clientFile} setClientFile={setClientFile} />
          )}
          {currTimelineStep === PREAPPROVAL_TIMELINE_STEPS.INSURANCE && (
            <Payers clientFile={clientFile} setClientFile={setClientFile} />
          )}
          {currTimelineStep === PREAPPROVAL_TIMELINE_STEPS.CONSENT_FORM && (
            <ConsentForm refreshClientFile={fetchState} clientFile={clientFile} />
          )}
        </Col>
      </Row>
    </>
  );
};
