import moment, { Moment } from "moment";

import { Weekday } from "../types/general";

export const getDatesBetween = <TOutput = moment.Moment>({
  startDate,
  endDate,
  interval,
  formatter = (date) => date as TOutput,
}: {
  startDate: Moment;
  endDate: Moment;
  interval: "week" | "month" | "day";
  formatter?: (date: Moment) => TOutput;
}): TOutput[] => {
  const dates: TOutput[] = [];
  let currentDate = moment(startDate);

  while (currentDate <= endDate) {
    dates.push(formatter(currentDate));
    currentDate = moment(currentDate).add(1, interval);
  }

  return dates;
};

export function combineDateAndTime({
  dateMoment,
  timeMoment,
}: {
  dateMoment: Moment;
  timeMoment: Moment;
}) {
  return moment(timeMoment).set({
    year: dateMoment.year(),
    month: dateMoment.month(),
    date: dateMoment.date(),
  });
}

export const getWeekOfMonth = (moment: Moment) => {
  return 1 + Math.floor((moment.date() - 1) / 7);
};

export const floorToXMinutes = ({ date, minutes }: { date: Date; minutes: number }) => {
  const dateMoment = moment(date);
  const dateMinutes = date.getMinutes();
  const remainder = dateMinutes % minutes;

  dateMoment.subtract(remainder, "minutes");

  dateMoment.set("millisecond", 0);
  dateMoment.set("seconds", 0);
  return dateMoment.toDate();
};

export const getWeekdayByIndex = (index: number): Weekday | undefined => {
  const weekdays = Object.values(Weekday);
  return weekdays[index];
};
