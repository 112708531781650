import { IClientFile, isDefined } from "@finni-health/shared";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useContext } from "react";

import { AuthContext } from "../components/AuthProvider";
import { IntakeComplete } from "../components/ClientOnboardingFlow/IntakeComplete";
import { PreapprovalComplete } from "../components/ClientOnboardingFlow/PreapprovalComplete";
import { Welcome } from "../components/ClientOnboardingFlow/Welcome";
import { PageName } from "../consts";
import { isIntakeComplete, isPreapprovalComplete } from "../helpers/client-file-status";
import { usePageViewTracking } from "../hooks/usePageViewTracking";
import * as FirestoreService from "../services/firestore";
import { Loading } from "./Loading";

export const ClientFileFlow: React.FC = () => {
  const { guardian } = useContext(AuthContext);
  const queryClient = useQueryClient();

  usePageViewTracking(PageName.CLIENT_FILE_FLOW);

  // TODO: Deprecate clientFile
  const { data: clientFile, isLoading } = useQuery<IClientFile | undefined>({
    queryKey: ["getClientFileByGuardianId", guardian.id],
    queryFn: () => FirestoreService.getClientFileByGuardianId(guardian.id),
    enabled: isDefined(guardian),
  });

  const invalidateGetClientFileQuery = async () => {
    await queryClient.invalidateQueries({
      queryKey: ["getClientFileByGuardianId", guardian.id],
    });
  };

  if (isLoading || !clientFile) {
    return <Loading pageName={PageName.CLIENT_FILE_FLOW} />;
  }

  if (!isPreapprovalComplete(clientFile)) {
    return <Welcome clientFile={clientFile} />;
  }

  if (!isIntakeComplete(clientFile)) {
    return (
      <PreapprovalComplete
        clientFile={clientFile}
        refreshClientFile={invalidateGetClientFileQuery}
      />
    );
  }

  return <IntakeComplete clientFile={clientFile} />;
};
