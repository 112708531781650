import { z } from "zod";

import { AdonisClaimStatus, AppointmentBillingStatus } from "../types";

export const createAdonisClaimEndpointRequestSchema = z.object({
  completedAppointmentIds: z.array(z.string()),
  timeZone: z.string(),
});

export type ICreateAdonisClaimEndpointRequestSchema = z.input<
  typeof createAdonisClaimEndpointRequestSchema
>;

export const getBundledAppointmentsEndpointRequestSchema = z.object({
  completedAppointmentId: z.string(),
  timeZone: z.string(),
});

export type IGetBundledAppointmentsEndpointRequestSchema = z.input<
  typeof getBundledAppointmentsEndpointRequestSchema
>;

export const getBundledAppointmentsEndpointResponseSchema = z.object({
  clientId: z.string(),
  dateStr: z.string(),
  renderingProviderNpi: z.string().optional(),
  adonisClaimId: z.string().optional(),
  appointmentIds: z.array(z.string()).optional(),
  error: z.string().optional(),
});

export type IGetBundledAppointmentsEndpointResponseSchema = z.input<
  typeof getBundledAppointmentsEndpointResponseSchema
>;

export const createAdonisClaimByClinicEndpointRequestSchema = z.object({
  clinicId: z.string(),
  startMs: z.number(),
  endMs: z.number(),
  timeZone: z.string(),
  isShadowMode: z.boolean().optional(),
});

export type ICreateAdonisClaimByClinicEndpointRequestSchema = z.input<
  typeof createAdonisClaimByClinicEndpointRequestSchema
>;

export const createAdonisClaimByClinicErrorResponseSchema = z.object({
  error: z.string(),
});

export type ICreateAdonisClaimByClinicErrorResponseSchema = z.input<
  typeof createAdonisClaimByClinicErrorResponseSchema
>;

export const adonisClaimResult = z.object({
  appointmentIds: z.array(z.string()),
  clientId: z.string(),
  claimId: z.string().optional(),
  error: z.string().optional(),
  status: z.nativeEnum(AppointmentBillingStatus).optional(),
});

export type IAdonisClaimResult = z.input<typeof adonisClaimResult>;

// Each result will have a claimId if successful, or an error if not
export const createAdonisClaimByClinicSuccessResponseSchema = z.object({
  results: z.array(adonisClaimResult),
});

export type ICreateAdonisClaimByClinicSuccessResponseSchema = z.input<
  typeof createAdonisClaimByClinicSuccessResponseSchema
>;

export const createAdonisPatientRequestSchema = z.object({
  clientId: z.string(),
});

export type ICreateAdonisPatientRequestSchema = z.input<typeof createAdonisPatientRequestSchema>;

export const createAdonisProviderRequestSchema = z.object({
  npis: z.array(z.string()),
});

export type ICreateAdonisProviderRequestSchema = z.input<typeof createAdonisProviderRequestSchema>;

export const getAdonisPatientRequestSchema = z.object({
  clientId: z.string(),
});

export type IGetAdonisPatientRequestSchema = z.input<typeof getAdonisPatientRequestSchema>;

export const getProviderByNpiRequestSchema = z.object({
  npi: z.string(),
});

export type IGetProviderByNpiRequestSchema = z.input<typeof getProviderByNpiRequestSchema>;

export const getAdonisClaimRequestSchema = z.object({
  claimId: z.string(),
});

export type IGetAdonisClaimRequestSchema = z.input<typeof getAdonisClaimRequestSchema>;

export const getAdonisClaimsRequestSchema = z.object({
  external_encounter_id: z.string(),
  page: z.number(),
  size: z.number(),
});

export type IGetAdonisClaimsRequestSchema = z.input<typeof getAdonisClaimsRequestSchema>;

export const adonisClaimStatusChangeRequestSchema = z.object({
  id: z.string(),
  external_encounter_id: z.string(),
  external_patient_id: z.string(),
  status: z.nativeEnum(AdonisClaimStatus),
  biller_notes: z.array(z.string()),
});

export type IAdonisClaimStatusChangeRequestSchema = z.input<
  typeof adonisClaimStatusChangeRequestSchema
>;

export const adonisFinalizedPaidRequestSchema = z.object({
  id: z.string(),
  patient_id: z.string(),
  external_patient_id: z.string(),
  external_encounter_id: z.string(),
  status: z.nativeEnum(AdonisClaimStatus),
  charge_amount: z.number(),
  payer_responsibility: z.number(),
  patient_responsibility: z.number(),
  contractual_adjustments: z.number(),
  other_adjustments: z.number(),
  biller_notes: z.array(z.string()),
});

export type IAdonisFinalizedPaidRequestSchema = z.input<typeof adonisFinalizedPaidRequestSchema>;

export const adonisWebhookRequestSchema = z.object({
  id: z.string(),
  timestamp: z.string(),
  object: z.string(),
  payload_version: z.string(),
  type: z.string(),
  data: z.union([adonisClaimStatusChangeRequestSchema, adonisFinalizedPaidRequestSchema]),
});

export type IAdonisWebhookRequestSchema = z.input<typeof adonisWebhookRequestSchema>;

export const adonisUpdateClaimStatusInternalRequestSchema = z.object({
  claimId: z.string(),
  status: z.nativeEnum(AppointmentBillingStatus),
  notes: z.array(z.string()),
});

export type IAdonisUpdateClaimStatusInternalRequestSchema = z.input<
  typeof adonisUpdateClaimStatusInternalRequestSchema
>;
