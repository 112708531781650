import { AnalyticsEvent, BaseEventPropertiesParentPortal } from "@finni-health/shared";
import { Row } from "antd";
import mixpanel from "mixpanel-browser";
import React, { useContext, useEffect, useMemo } from "react";
import Lottie from "react-lottie-player";

import { AuthContext } from "../components/AuthProvider";
import foxHiAnimation from "../lottie/fox-hi.json";

interface IProps {
  pageName?: string;
}

export const Loading: React.FC<IProps> = ({ pageName }: IProps) => {
  const { guardian, clinic } = useContext(AuthContext);

  const baseProperties: BaseEventPropertiesParentPortal = useMemo(() => {
    return {
      guardian_id: guardian?.id,
      clinic_id: clinic?.id,
      clinic_name: clinic?.name,
      clinic_display_name: clinic?.displayName,
    };
  }, [guardian, clinic]);

  useEffect(() => {
    if (pageName) {
      mixpanel.time_event(AnalyticsEvent.PAGE_LOAD_TIME);
      return () => {
        mixpanel.track(AnalyticsEvent.PAGE_LOAD_TIME, {
          baseProperties: baseProperties,
          pageName: pageName,
          app: "Parent Portal",
        });
      };
    }
  }, [pageName]);

  return (
    <Row justify="center" align="middle" style={{ height: "100vh" }}>
      <div>
        <Lottie play loop animationData={foxHiAnimation} style={{ width: 100, height: 100 }} />
      </div>
    </Row>
  );
};
