export enum UTILIZATION_DATE_RANGE {
  ONE_MONTH = 1,
  THREE_MONTHS = 3,
  SIX_MONTHS = 6,
}

export enum UTILIZATION_INCREMENT {
  WEEK = "week",
  MONTH = "month",
}

export enum GRAPH_COLOR {
  ORANGE = "#E6820D",
  BLUE = "#0074D9",
}

export type BarChartItem<T> = {
  id: string;
  label: string;
  value: number;
  color: GRAPH_COLOR;
  type: T;
};

// EFFICIENCY
export enum EFFICIENCY_GROUP {
  EMPLOYEES = "employees",
  CLIENTS = "clients",
}

export enum EFFICIENCY_GROUP_SEGMENTS {
  ALL = "all",
  EMPLOYEES = EFFICIENCY_GROUP.EMPLOYEES,
  CLIENTS = EFFICIENCY_GROUP.CLIENTS,
}

export type EfficiencyGroup = EFFICIENCY_GROUP;
export type EfficiencyGroupSegment = EFFICIENCY_GROUP_SEGMENTS;

export const EFFICIENCY_GROUP_COLORS_MAP: Record<EfficiencyGroup, GRAPH_COLOR> = {
  [EFFICIENCY_GROUP.CLIENTS]: GRAPH_COLOR.BLUE,
  [EFFICIENCY_GROUP.EMPLOYEES]: GRAPH_COLOR.ORANGE,
};
