import { AnalyticsEvent, AppTitle, BaseEventPropertiesParentPortal } from "@finni-health/shared";
import { isEmpty } from "lodash";
import mixpanel, { Dict } from "mixpanel-browser";
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from "react";

import { ClientOnboardingFlow, PageName } from "../consts";
import { AuthContext } from "./AuthProvider";

interface TrackEventParams {
  eventName: AnalyticsEvent;
  additionalProperties?: Dict;
}

interface IMixpanelContext {
  trackEvent: (params: TrackEventParams) => void;
  trackPageView: (title: PageName | ClientOnboardingFlow) => void;
}

const MixpanelContext = createContext<IMixpanelContext | undefined>(undefined);

export const useMixpanel = () => {
  const context = useContext(MixpanelContext);
  if (context === undefined) {
    throw new Error("useMixpanel must be used within a MixpanelProvider.");
  }
  return context;
};

const useUserIdentificationTracking = () => {
  const { guardian, clinic } = useContext(AuthContext);
  useEffect(() => {
    if (isEmpty(guardian) || isEmpty(clinic)) return;
    mixpanel.identify(guardian.id);
    mixpanel.people.set({
      guardian_id: guardian.id,
      clinic_id: clinic.id,
      clinic_name: clinic.name,
      clinic_display_name: clinic.displayName,
    });

    return () => mixpanel.reset();
  }, [guardian, clinic]);
};

export const MixpanelProvider = ({ children }: { children: ReactNode }) => {
  const { guardian, clinic } = useContext(AuthContext);

  const baseProperties: BaseEventPropertiesParentPortal = useMemo(() => {
    return {
      guardian_id: guardian.id,
      clinic_id: clinic.id,
      clinic_name: clinic.name,
      clinic_display_name: clinic.displayName,
      app: AppTitle.PARENT_PORTAL,
    };
  }, [guardian, clinic]);

  const trackEvent = useCallback(
    ({ eventName, additionalProperties }: TrackEventParams) => {
      try {
        mixpanel.track(eventName, {
          ...baseProperties,
          ...additionalProperties,
        });
      } catch (error) {
        console.error("Failed to track event:", error);
      }
    },
    [baseProperties]
  );

  const trackPageView = (title: PageName | ClientOnboardingFlow) => {
    mixpanel.track_pageview({ ...baseProperties, page: title });
  };

  useUserIdentificationTracking();

  return (
    <MixpanelContext.Provider value={{ trackEvent, trackPageView }}>
      {children}
    </MixpanelContext.Provider>
  );
};
