import "moment-timezone";

import moment, { Moment } from "moment";
import { datetime, Frequency, Options, RRule, RRuleSet, rrulestr, Weekday } from "rrule";

import { WEEKDAY_TWO_LETTERS } from "../consts";
import {
  CalendarRecurrenceOption,
  IParsedRRule,
  IWeeklyRecurrenceOption,
  MonthlyRecurrenceOption,
} from "../types";
import { getWeekOfMonth } from "./dates";
import { isTruthy, Nullable } from "./type-helpers";

/**
 * Wrapper around the rrule library.
 *
 * The RRule library expects dates to be timezone agnostic.
 * For example, if I want to add an exclusion date to an RRuleSet that has the "America/New_York"
 * for 10AM, I must pass in 2024-10-16T10:00:00.000Z. This is counter-intuitive, since that is actually
 * 6 am EST, if you treat that timestamp at face value as a UTC string.
 * See https://www.npmjs.com/package/rrule#important-use-utc-dates
 *
 * For this reason, the rrule library should not be interacted with
 * directly. Instead, this class should be used since it handles those
 * sorts of conversions.
 *
 **/
export class FinniRRuleSet {
  private rruleSet: RRuleSet;

  private static convertMsToRRuleCompatibleDate = (ms: number, tzid?: string) => {
    const date = tzid ? moment(ms).tz(tzid) : moment(ms);
    return datetime(
      date.year(),
      // moment uses 0-11, but we need 1-12
      date.month() + 1,
      date.date(),
      date.hours(),
      date.minutes(),
      date.seconds()
    );
  };

  public static fromParsedRRule({
    parsedRRule,
    startDatetime,
    tzid,
  }: {
    parsedRRule: IParsedRRule;
    startDatetime: Moment;
    tzid: string;
  }) {
    if (parsedRRule.option === CalendarRecurrenceOption.NO_REPEAT) {
      return null;
    }

    const rruleOptions: Partial<Options> = {
      tzid,
      // See https://www.npmjs.com/package/rrule#timezone-support.
      // Some funkiness occurs when passing in a date object.
      dtstart: FinniRRuleSet.convertMsToRRuleCompatibleDate(startDatetime.valueOf(), tzid),
    };

    switch (parsedRRule.option) {
      case CalendarRecurrenceOption.DAILY: {
        rruleOptions.freq = RRule.DAILY;
        break;
      }

      case CalendarRecurrenceOption.BIWEEKLY:
      case CalendarRecurrenceOption.WEEKLY: {
        if (!parsedRRule.weeklyOption) {
          return null;
        }
        rruleOptions.freq = RRule.WEEKLY;
        rruleOptions.interval = parsedRRule.option === CalendarRecurrenceOption.WEEKLY ? 1 : 2;
        rruleOptions.byweekday = parsedRRule.weeklyOption
          .map((option, index) => (option ? RRule[WEEKDAY_TWO_LETTERS[index]] : undefined))
          .filter(isTruthy);

        break;
      }

      case CalendarRecurrenceOption.MONTHLY: {
        if (!parsedRRule.monthlyOption?.startDate) {
          return null;
        }

        rruleOptions.freq = RRule.MONTHLY;
        if (parsedRRule.monthlyOption.option === MonthlyRecurrenceOption.BY_DAY) {
          const weekOfMonth = getWeekOfMonth(parsedRRule.monthlyOption.startDate);
          const weekDay = parsedRRule.monthlyOption.startDate
            .format("dd")
            .toUpperCase() as (typeof WEEKDAY_TWO_LETTERS)[number];
          rruleOptions.byweekday = RRule[weekDay].nth(weekOfMonth);
        } else {
          rruleOptions.bymonthday = parsedRRule.monthlyOption.startDate.date();
        }
        break;
      }
    }

    if (parsedRRule.endDate) {
      rruleOptions.until = FinniRRuleSet.convertMsToRRuleCompatibleDate(
        parsedRRule.endDate.valueOf(),
        tzid
      );
    }

    const rrule = new RRule(rruleOptions);
    const rruleSet = new RRuleSet();
    rruleSet.rrule(rrule);
    return new FinniRRuleSet(rruleSet);
  }

  public static fromString(rrule: string) {
    // This library explicitly looks for new line characters, but doesn't recognize \n as a newline character.
    // Convert \n to a newline character here.
    const rruleSet = rrulestr(rrule.replace(/\\n/g, String.fromCharCode(10)), {
      forceset: true,
    }) as RRuleSet;

    return new FinniRRuleSet(rruleSet);
  }

  private constructor(rruleSet: RRuleSet) {
    this.rruleSet = rruleSet;
  }

  public toString() {
    return this.rruleSet.toString();
  }

  /**
   * Converts the rrule set to an object that is easily readable by the UI (IParsedRRule), mainly the "RecurrencePicker"
   */
  public toParsedRrule() {
    const rruleInstance = this.getRrule();
    if (!rruleInstance) {
      console.error("Unable to get rrule from rruleSet");
      return null;
    }
    // The RRule library lies about the type of these options. They are all (or mostly all) nullable
    const rruleOptions: Nullable<typeof rruleInstance.options> = rruleInstance.options;

    const parsedRRule: IParsedRRule = {
      option: CalendarRecurrenceOption.NO_REPEAT,
    };

    switch (rruleOptions.freq) {
      case Frequency.DAILY: {
        parsedRRule.option = CalendarRecurrenceOption.DAILY;
        break;
      }
      case Frequency.WEEKLY: {
        if (rruleOptions.interval === 2) {
          parsedRRule.option = CalendarRecurrenceOption.BIWEEKLY;
        } else {
          parsedRRule.option = CalendarRecurrenceOption.WEEKLY;
        }
        // rruleOptions.byweekday is a number that corresponds to an Enum, convert these to js Weekday numbers
        const weekdays =
          rruleOptions.byweekday?.map((day) => new Weekday(day).getJsWeekday()) || [];
        // weekly option is an array of booleans, so convert the above weekday numbers to that array
        parsedRRule.weeklyOption = Array.from({ length: 7 }, (_, index) =>
          weekdays.includes(index)
        ) as IWeeklyRecurrenceOption;
        break;
      }
      case Frequency.MONTHLY: {
        parsedRRule.option = CalendarRecurrenceOption.MONTHLY;
        if (rruleOptions.byweekday?.length) {
          parsedRRule.monthlyOption = {
            option: MonthlyRecurrenceOption.BY_DAY,
          };
        } else {
          parsedRRule.monthlyOption = {
            option: MonthlyRecurrenceOption.BY_MONTH_DAY,
          };
        }
        break;
      }
      default: {
        console.error("Unsupported RRule:", this.rruleSet.toString());
      }
    }
    const until = this.getRecurrsUntilValue();
    if (until !== Number.MAX_SAFE_INTEGER) {
      parsedRRule.endDate = moment(until);
    }
    return parsedRRule;
  }

  private getTzid() {
    return this.getRrule()?.origOptions?.tzid || "Etc/UTC";
  }

  public getRecurrsUntilValue() {
    const rruleUntilDate = this.getRrule()?.options?.until?.valueOf();

    if (!rruleUntilDate) {
      return Number.MAX_SAFE_INTEGER;
    }

    // RRule library doesn't output true UTC Dates. See https://www.npmjs.com/package/rrule#timezone-support
    const untilDate = moment.utc(rruleUntilDate).tz(this.getTzid(), true).valueOf();

    return untilDate;
  }

  // There is no easy way to update the Until date on an RRule, so we have to copy over
  // the rrule in place and insert the new date
  public updateUntilValue(date: Date) {
    const until = FinniRRuleSet.convertMsToRRuleCompatibleDate(date.valueOf(), this.getTzid());
    const rrule = this.getRrule();
    const exclusions = this.getExclusions();
    const newRrule = new RRule({ ...rrule?.origOptions, until });
    const newRRuleSet = new RRuleSet();
    newRRuleSet.rrule(newRrule);
    exclusions.map((exclusion) => newRRuleSet.exdate(exclusion));
    this.rruleSet = newRRuleSet;
  }

  // We only ever use one rrule in an RRule set, so just return the first
  private getRrule() {
    return this.rruleSet.rrules()[0] as RRule | undefined;
  }

  public getExclusions() {
    return this.rruleSet.exdates();
  }

  public addExclusion(date: Date) {
    this.rruleSet.exdate(
      FinniRRuleSet.convertMsToRRuleCompatibleDate(date.valueOf(), this.getTzid())
    );
  }

  public copyExclusionsFrom(rruleSet: FinniRRuleSet) {
    for (const rruleDate of rruleSet.getExclusions()) {
      // Convert the date the RRule library spits out to the actual date
      const actualDate = moment.utc(rruleDate).tz(rruleSet.getTzid(), true);

      // Convert into "this" timezone and add it to the rruleSet exclusions
      this.rruleSet.exdate(
        FinniRRuleSet.convertMsToRRuleCompatibleDate(actualDate.valueOf(), this.getTzid())
      );
    }
  }

  /**
   * Returns all the occurrences of the rrule between after and before.
   * The inc keyword defines what happens if after and/or before are
   * themselves occurrences. With inc == True, they will be included in the
   * list, if they are found in the recurrence set.
   */
  public between(start: Date, end: Date, inc: boolean) {
    return (
      this.rruleSet
        .between(
          FinniRRuleSet.convertMsToRRuleCompatibleDate(start.valueOf()),
          FinniRRuleSet.convertMsToRRuleCompatibleDate(end.valueOf()),
          inc
        )
        // See https://www.npmjs.com/package/rrule#important-use-utc-dates
        .map((date) => moment(date).utc().local(true))
    );
  }
}
