import { ResponsiveProvider } from "@finni-health/ui";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import mixpanel from "mixpanel-browser";
import { ReactElement, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { AuthProvider } from "./components/AuthProvider";
import { MixpanelProvider } from "./components/MixpanelProvider";
import { PrivateRoute } from "./components/PrivateRoute";
import { ForgotPassword } from "./pages/ForgotPassword";
import { Home } from "./pages/Home";
import { InquiryFlow } from "./pages/InquiryFlow";
import { Loading } from "./pages/Loading";
import { Login } from "./pages/Login";
import { Schedule } from "./pages/Schedule";
import { SecretSignUp } from "./pages/SecretSignUp";
import app from "./services/firebase";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || "bogus");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      networkMode: "offlineFirst",
    },
    mutations: {
      networkMode: "offlineFirst",
    },
  },
});

try {
  mixpanel.init(`${process.env.REACT_APP_MIXPANEL_PROJECT_ID}`, {
    debug: process.env.NODE_ENV === "development",
    persistence: "localStorage",
    track_pageview: true,
  });
} catch (error) {
  console.error("Failed to initialize Mixpanel:", error);
}

function App(): ReactElement {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, () => {
      setIsInitialized(true);
    });

    // Cleanup auth listener when component unmounts
    return () => unsubscribe();
  }, []);

  return isInitialized ? (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ResponsiveProvider>
          <MixpanelProvider>
            <Router>
              <Switch>
                <Route path="/:clinicName/signup" component={SecretSignUp} />
                <Route path="/inquire" component={InquiryFlow} />
                <Route path="/login" component={Login} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/schedule/:clientFileHash" component={Schedule} />
                <PrivateRoute path="/">
                  <Home />
                </PrivateRoute>
              </Switch>
            </Router>
          </MixpanelProvider>
        </ResponsiveProvider>
      </AuthProvider>
    </QueryClientProvider>
  ) : (
    <Loading />
  );
}

export default App;
