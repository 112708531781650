import { IUser, ProviderType } from "./general";

// Predefined event types
export enum AnalyticsEvent {
  ACTIVE_SESSION_STARTED = "Active Session Started",
  ACTIVE_SESSION_LOADED = "Active Session Loaded",
  ACTIVE_SESSION_ENDED = "Active Session Ended",
  ADDING_CARE_DOCUMENTS = "Adding Care Documents",
  ADDING_KIDDO_DETAILS = "Adding Kiddo Details",
  ADDING_INSURANCE = "Adding Insurance",
  APPOINTMENT_CREATED = "Appointment Created",
  INTAKE_FUNNEL_COMPLETE = "Intake Funnel Complete",
  INTAKE_QUESTIONNAIRE_VIEWED = "Intake Questionnaire Viewed",
  MEASUREMENT_CREATED = "Measurement Created",
  MEASUREMENT_UPDATED = "Measurement Updated",
  MEASUREMENT_DELETED = "Measurement Deleted",
  NARRATIVE_GENERATED = "Narrative Generated",
  WORKFLOW_NEXT_STEP = "Workflow Next Step",
  NOTE_CREATED = "Note Created",
  NOTE_UPDATED = "Note Updated",
  NOTE_DELETED = "Note Deleted",
  PAGE_LOAD_TIME = "Page Load Time",
  WORKFLOW_PREVIOUS_STEP = "Workflow Previous Step",
  PROGRAM_TEMPLATE_UPDATED = "Program Template Updated",
  PROGRAM_TEMPLATE_CREATED = "Program Template Created",
  PROGRAM_TEMPLATE_DELETED = "Program Template Deleted",
  ROUTE_CHANGE_LOAD_TIME = "Route Change Load Time",
  SIGNING_CONSENT_FORMS = "Signing Consent Forms",
  TARGET_CREATED = "Target Created",
  TARGET_UPDATED = "Target Updated",
  TARGET_DELETED = "Target Deleted",
  TASKS_TO_BE_COMPELETED = "Tasks To Be Completed",
  V2_PROGRAM_SORT = "V2 Program Sort",
  V2_TAG_MENU = "V2 Tag Menu",
}

export enum WorkflowParentPortal {
  INTAKE_FUNNEL = "Intake Funnel",
}

interface ClinicEventProperties {
  clinic_id: string;
  clinic_name: string;
  clinic_display_name: string;
}

export interface BaseEventProperties extends ClinicEventProperties {
  user_id: string;
  provider_type: ProviderType;
}

export interface BaseEventPropertiesParentPortal extends ClinicEventProperties {
  guardian_id: string;
}

export interface TrackEventProperties {
  eventName: AnalyticsEvent;
  user: Pick<IUser, "id" | "providerType" | "clinicId">;
  additionalProperties?: Record<string, any>;
}
