import { BillingCode } from "../types";

/**
 * Map of payerIds to billing codes that require a complete overlap of another billing
 * code.
 */
export const PAYERIDS_OVERLAP_REQUIREMENTS_MAP = {
  Qo0a6qdjsLv8KLTOYnUm: {
    [BillingCode.CODE_97155]: [BillingCode.CODE_97153],
  },
};

/**
 * PayerIds that have their own notes portal, and therefore don't require notes
 * in Motivity/Orbit for the billing codes in the {@link CODES_WITH_NOTES_PORTAL} array.
 */
export const PAYERIDS_WITH_NOTES_PORTAL = [
  "Qo0a6qdjsLv8KLTOYnUm", // Central Michigan Community Mental Health
];

/**
 * Billing codes whose notes are written in the payer's notes portal and not in
 * Motivity/Orbit.
 *
 * Applies for payers in the {@link PAYERIDS_WITH_NOTES_PORTAL} array.
 */
export const CODES_WITH_NOTES_PORTAL = [
  BillingCode.CODE_97151,
  BillingCode.CODE_97152,
  BillingCode.CODE_0362T,
  BillingCode.CODE_97155,
  BillingCode.CODE_97156,
  BillingCode.CODE_97158,
];
