import { isDefined } from "@finni-health/shared";
import { useResponsive } from "@finni-health/ui";
import { useQuery } from "@tanstack/react-query";
import { Alert, Col, Row, Typography } from "antd";
import { useHistory } from "react-router-dom";

import { useAuthContext } from "../components/AuthProvider";
import { CurrentInsuranceCard } from "../components/Insurance/CurrentInsuranceCard";
import { NewInsuranceCard } from "../components/Insurance/NewInsuranceCard";
import { PageName } from "../consts";
import { usePageViewTracking } from "../hooks/usePageViewTracking";
import { useQueryParams } from "../hooks/useQueryParams";
import * as FirestoreService from "../services/firestore";
import { Loading } from "./Loading";

const { Title } = Typography;

export const ConfirmInsurancePage = () => {
  const { guardian } = useAuthContext();
  const history = useHistory();
  const queryParams = useQueryParams();
  const clientId = queryParams.get("clientId");
  const { isMobile } = useResponsive();

  usePageViewTracking(PageName.CONFIRM_INSURANCE);

  const { data: client, isLoading: isClientLoading } = useQuery({
    queryKey: ["getClientById", clientId],
    queryFn: () => (clientId ? FirestoreService.getClientById(clientId) : undefined),
    enabled: isDefined(clientId),
  });

  const { data: clientFile, isLoading: isClientFileLoading } = useQuery({
    queryKey: ["getClientFileByGuardianIdAndClientId", guardian?.id, clientId],
    queryFn: () =>
      guardian?.id && clientId
        ? FirestoreService.getClientFileByGuardianIdAndClientId(guardian.id, clientId)
        : undefined,
    enabled: isDefined(guardian?.id) && isDefined(clientId),
  });

  if (isClientLoading || isClientFileLoading) {
    return <Loading pageName={PageName.CONFIRM_INSURANCE} />;
  }

  /**
   * This is a guard to prevent the user from accessing
   * the page without a valid clientId defined in the URL
   */
  if (!client || !clientFile) {
    history.push("/");
    return <Loading />;
  }

  return (
    <Row style={{ flexFlow: "column wrap", rowGap: "30px", alignItems: "center", width: "100%" }}>
      <Title
        level={4}
      >{`Please confirm your new insurance information for ${client.firstName} ${client.lastName}:`}</Title>
      <Row style={{ width: "100%", maxWidth: isMobile ? "348px" : "720px" }}>
        <Col span={24}>
          <Alert
            message={
              <>
                Don&apos;t see your new plan listed or don&apos;t have your new plan information
                yet? No problem! Contact our team at{" "}
                <a href="mailto:care@finnihealth.com">care@finnihealth.com</a>, and we&apos;ll walk
                you through the next steps.
              </>
            }
            type="info"
          />
        </Col>
      </Row>
      <Row
        style={{
          gap: 20,
          justifyContent: "center",
          alignItems: "stretch",
        }}
      >
        <CurrentInsuranceCard client={client} />
        <NewInsuranceCard client={client} clientFile={clientFile} />
      </Row>
    </Row>
  );
};
