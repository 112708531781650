import { AnalyticsEvent, AppTitle, IClientFile, WorkflowParentPortal } from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { useResponsive } from "@finni-health/ui";
import { Button, Col, Divider, Row, Steps, Typography } from "antd";
import React, { useContext, useEffect } from "react";
import Lottie from "react-lottie-player";

import { ClientOnboardingFlow } from "../../consts";
import { isDocumentsComplete, isPayersComplete } from "../../helpers/client-file-status";
import { usePageViewTracking } from "../../hooks/usePageViewTracking";
import puzzleAnimation from "../../lottie/puzzle.json";
import { AuthContext } from "../AuthProvider";
import { useMixpanel } from "../MixpanelProvider";
import { DiagnosticsUploadTask } from "./DiagnosticsUploadsTask";
import { MissingInsuranceTask } from "./MissingInsuranceTask";

const { Title, Text } = Typography;

interface IProps {
  clientFile: IClientFile;
}

export const IntakeComplete: React.FC<IProps> = ({ clientFile }: IProps) => {
  const { guardian } = useContext(AuthContext);

  const { isMobile } = useResponsive();

  const payersComplete = isPayersComplete(clientFile);
  const documentsComplete = isDocumentsComplete(clientFile?.documents);
  const userHasTaskCards = payersComplete || documentsComplete;
  const { trackEvent } = useMixpanel();

  usePageViewTracking(ClientOnboardingFlow.INTAKE_COMPLETE);
  useEffect(() => {
    trackEvent({
      eventName: AnalyticsEvent.INTAKE_FUNNEL_COMPLETE,
      additionalProperties: {
        app: AppTitle.PARENT_PORTAL,
        workflow: WorkflowParentPortal.INTAKE_FUNNEL,
      },
    });
  }, []);

  return (
    <>
      <Row justify="center" style={{ width: "100%" }}>
        <Title level={4} style={{ marginTop: 30 }}>
          Thank you for filling out the intake questionnaire, {guardian.firstName}!
        </Title>
        <Text>
          A team member will be reaching out within the next business day to begin the enrollment
          process.
        </Text>
      </Row>
      <Row justify="center" style={{ marginTop: 30, marginBottom: 50, width: "100%" }}>
        <Steps
          direction={isMobile ? "vertical" : "horizontal"}
          type={isMobile ? "default" : "navigation"}
          current={2}
          style={{ width: isMobile ? "auto" : "100%" }}
        >
          <Steps.Step title="Completed!" description="Pre-approval questionnaire" />
          <Steps.Step title="Completed!" description="Intake questionnaire" />
          <Steps.Step title="Next 48 Hours" description="Schedule an assessment" />
          <Steps.Step title="Next 1-2 Weeks" description="Create a unique care plan" />
          <Steps.Step title="Next 4-6 Weeks" description="Sessions begin!" />
        </Steps>
      </Row>
      <Row justify="center" style={{ width: "100%", zIndex: 999 }}>
        <Text strong style={{ display: "block", fontSize: 14 }}>
          Have questions? <br />
          Call
          <Button
            type="text"
            href="tel:(575) 520-6074"
            style={{
              marginLeft: 5,
              marginRight: 5,
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 14,
              fontWeight: 500,
              background: COLORS.LIGHT_PURPLE + "26",
            }}
          >
            (575) 520-6074
          </Button>
          or email
          <Button
            type="text"
            href="mailto:care@finnihealth.com?subject=I have a question about the Finni enrollment process!"
            style={{
              marginLeft: 5,
              marginRight: 5,
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 14,
              fontWeight: 500,
              background: COLORS.LIGHT_PURPLE + "26",
            }}
          >
            care@finnihealth.com
          </Button>
        </Text>
      </Row>
      <Row justify="center" style={{ width: "100%" }}>
        {userHasTaskCards && (
          <Row style={{ marginTop: 50 }}>
            <Col span={isMobile ? 24 : 12}>
              {documentsComplete && (
                <>
                  <Row justify="center">
                    <DiagnosticsUploadTask clientFile={clientFile} />
                  </Row>
                </>
              )}
            </Col>
            <Col span={isMobile ? 24 : 12}>
              {payersComplete && (
                <Row justify="center">
                  {isMobile && (
                    <Divider
                      type="vertical"
                      style={{ margin: 0, height: 20, borderLeftWidth: "2px" }}
                    />
                  )}
                  <MissingInsuranceTask clientFile={clientFile} />
                </Row>
              )}
            </Col>
          </Row>
        )}
        <Row style={{ marginTop: -50 }}>
          <div>
            <Lottie play loop animationData={puzzleAnimation} style={{ width: 500 }} />
          </div>
        </Row>
      </Row>
    </>
  );
};
