//colors
export const COLORS = {
  PRIMARY: "#ed762f",
  LIGHT_ORANGE: "#FA8E46",
  NEUTRAL_ORANGE: "#eec1a9",

  LIGHT_PURPLE: "#D1BCE7",
  DARK_PURPLE: "#4A3A54",

  GREEN: "#06d6a0",
  STANDARD_GREEN: "green",

  YELLOW: "#ffd166",
  DARK_YELLOW: "#F46E65",

  STANDARD_RED: "#FF0000",
  RED: "#ef476f",

  NEUTRAL_BLUE: "#CDEDF6",
  LIGHT_BLUE: "#0096c7",
  BLUE: "#108EE9",

  WHITE: "#ffffff",
  GRAY: "#dfdfdf",
  LIGHT_GRAY: "#e4e4e4",
  EXTRA_LIGHT_GRAY: "#f5f5fa",
  BORDER_GRAY: "#e6e6f5",
  DARK_GRAY: "#bfbfbf",
  GRAY_5: "#9B9BC2",
  LINK: "#1677ff",
};

export const MOBILE_WINDOW_WIDTH_MAX = 850;
export const EXTRA_SMALL_MOBILE_WIDTH_MAX = 375;
export const PYLON_HELP_URL = "https://finni.help.usepylon.com/";
export const MAX_Z_INDEX = 9999999;
export const OVERLAY_Z_INDEX = 999999;
