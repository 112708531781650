import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { EFFICIENCY_GROUP_SEGMENTS, UTILIZATION_INCREMENT } from "../types/utilization";

const c = initContract();
export const utilizationContract = c.router({
  // GET Efficiency for a specific clinic
  getClinicEfficiency: {
    method: "GET",
    path: "/clinics/:clinicId/utilization/efficiency",
    pathParams: z.object({
      clinicId: z.string(),
    }),
    query: z.object({
      startMs: z.coerce.number(),
      endMs: z.coerce.number(),
      segment: z.enum([
        EFFICIENCY_GROUP_SEGMENTS.ALL,
        EFFICIENCY_GROUP_SEGMENTS.CLIENTS,
        EFFICIENCY_GROUP_SEGMENTS.EMPLOYEES,
      ]),
      increment: z.enum([UTILIZATION_INCREMENT.WEEK, UTILIZATION_INCREMENT.MONTH]),
    }),
    responses: {
      200: z.object({
        data: z.object({
          clinicId: z.string(),
          endMs: z.number(),
          segment: z.string(),
          startMs: z.number(),
          efficiencies: z.object({
            employees: z.array(
              z.object({
                employee: z.object({
                  id: z.string(),
                  name: z.string(),
                }),
                efficiencies: z.record(z.string(), z.number()),
                totalEfficiency: z.number(),
              })
            ),
            clients: z.array(
              z.object({
                client: z.object({
                  id: z.string(),
                  name: z.string(),
                }),
                efficiencies: z.record(z.string(), z.number()),
                totalEfficiency: z.number(),
              })
            ),
          }),
        }),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
