import { IClientFile } from "@finni-health/shared";
import { COLORS, useResponsive } from "@finni-health/ui";
import { Button, Col, Divider, Row, Typography } from "antd";
import React, { useContext } from "react";
import Lottie from "react-lottie-player";

import { ClientOnboardingFlow } from "../../consts";
import { isDocumentsComplete, isPayersComplete } from "../../helpers/client-file-status";
import { usePageViewTracking } from "../../hooks/usePageViewTracking";
import studyAnimation from "../../lottie/study.json";
import { AuthContext } from "../AuthProvider";
import { DiagnosticsUploadTask } from "./DiagnosticsUploadsTask";
import { MissingInsuranceTask } from "./MissingInsuranceTask";
import { PreapprovalTasksCard } from "./PreapprovalTasksCard";

const { Title, Text } = Typography;

interface IProps {
  clientFile: IClientFile;
}

export const Welcome: React.FC<IProps> = ({ clientFile }: IProps) => {
  const { clinic, guardian } = useContext(AuthContext);

  const { isMobile } = useResponsive();

  const payersComplete = isPayersComplete(clientFile);
  const documentsComplete = isDocumentsComplete(clientFile?.documents);
  const userHasTaskCards = payersComplete || documentsComplete;

  usePageViewTracking(ClientOnboardingFlow.WELCOME);

  return (
    <>
      <Row justify="center">
        <Col span={isMobile ? 24 : 12}>
          <Title level={4} style={{ marginBottom: 10 }}>
            Welcome to {clinic.displayName}, {guardian.firstName}!
          </Title>
          <Title level={5} style={{ marginTop: 0 }}>
            Get a headstart by completing your pre‑approval questionnaire.
          </Title>
          <Text>
            {`We'll be reaching out soon. In the meantime help us get started with
            your child's care by providing some background information about
            them. We also need to ensure that the care we can provide meets your
            goals and verify your insurance benefits (if using insurance).`}
          </Text>
          <Text strong style={{ display: "block", marginTop: 15, fontSize: 14 }}>
            Have questions?
            <br />
            Call
            <Button
              type="text"
              href="tel:(575) 520-6074"
              style={{
                marginLeft: 5,
                marginRight: 5,
                paddingLeft: 5,
                paddingRight: 5,
                fontSize: 14,
                fontWeight: 500,
                background: COLORS.LIGHT_PURPLE + "26",
              }}
            >
              (575) 520-6074
            </Button>
            or email
            <Button
              type="text"
              href="mailto:care@finnihealth.com?subject=I have a question about the Finni enrollment process!"
              style={{
                marginLeft: 5,
                marginRight: 5,
                paddingLeft: 5,
                paddingRight: 5,
                fontSize: 14,
                fontWeight: 500,
                background: COLORS.LIGHT_PURPLE + "26",
              }}
            >
              care@finnihealth.com
            </Button>
          </Text>
        </Col>
        {!isMobile && (
          <Col>
            <div>
              <Lottie play loop animationData={studyAnimation} style={{ width: 300 }} />
            </div>
          </Col>
        )}
      </Row>
      <Row style={{ marginTop: 50, width: "100%" }} justify="space-evenly">
        <Col
          span={isMobile ? "auto" : userHasTaskCards ? 14 : 20}
          style={{ marginBottom: 20, padding: "0 10px" }}
        >
          <PreapprovalTasksCard clientFile={clientFile} />
        </Col>
        {userHasTaskCards && (
          <Col span={isMobile ? "auto" : 10}>
            <>
              <Row justify="center">
                <DiagnosticsUploadTask clientFile={clientFile} />
              </Row>
            </>
            <Row justify="center">
              <Divider type="vertical" style={{ margin: 0, height: 20, borderLeftWidth: "2px" }} />
              <MissingInsuranceTask clientFile={clientFile} />
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};
