import { useEffect } from "react";

import { useMixpanel } from "../components/MixpanelProvider";
import { ClientOnboardingFlow, PageName } from "../consts";

export const usePageViewTracking = (pageName: PageName | ClientOnboardingFlow) => {
  const { trackPageView } = useMixpanel();

  useEffect(() => {
    try {
      trackPageView(pageName);
    } catch (error) {
      console.error(`Failed to track page view for ${pageName}:`, {
        error,
        pageName,
      });
    }
  }, []);
};
