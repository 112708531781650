import { IClient, IClientFile, IClinic, IGuardian } from "@finni-health/shared";
import { getAuth } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import app from "../services/firebase";
import {
  getClientByGuardianId,
  getClientFileByGuardianId,
  getClinicById,
  getGuardianById,
} from "../services/firestore";

export const AuthContext = React.createContext({} as Context);

interface Context {
  clientFile: IClientFile;
  client: IClient;
  guardian: IGuardian;
  clinic: IClinic;
  refresh: () => void;
}

export const AuthProvider = ({ children }: any) => {
  const [key, setKey] = useState<string>("");
  const [context, setContext] = useState<Context>({
    clientFile: {} as IClientFile,
    client: {} as IClient,
    guardian: {} as IGuardian,
    clinic: {} as IClinic,
    refresh: () => {
      // pass
    },
  });

  const refresh = () => {
    setKey(uuidv4());
  };

  useEffect(() => {
    const unsubscribe = getAuth(app).onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        const uid = firebaseUser.uid;

        const fetchContext = async () => {
          try {
            // TODO: Fetch guardian, clientFile, and clinic using React-Query
            const guardian = await getGuardianById(uid);
            const clientFile = await getClientFileByGuardianId(guardian.id);
            const client = await getClientByGuardianId(guardian.id);
            const clinic = await getClinicById(guardian.clinicId);

            setContext({ guardian, clinic, clientFile, client, refresh });
          } catch (err) {
            console.error(`Error setting auth: ${err}`);
          }
        };

        fetchContext().catch((e) => console.error("Error fetching context", e));
      }

      // Cleanup auth listener when component unmounts
      return () => unsubscribe();
    });
  }, [key]);

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
