import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { IClientAvailability } from "../types";

const c = initContract();
export const clientAvailabilitiesContract = c.router(
  {
    getLatestByClientId: {
      method: "GET",
      path: "/availabilities",
      pathParams: z.object({
        clientId: z.string(),
      }),
      query: z.object({
        clinicId: z.string(),
        /** necessary to authenticate requests from Parent Portal */
        guardianId: z.string().optional(),
      }),
      responses: {
        // TODO: Convert to zod schema
        200: c.type<IClientAvailability | null>(),
        400: z.object({
          message: z.string(),
        }),
        500: z.object({
          message: z.string(),
        }),
      },
    },
  },
  {
    pathPrefix: "/clients/:clientId",
  }
);
