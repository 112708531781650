import { useResponsive } from "@finni-health/ui";
import { Layout, Row } from "antd";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useHistory, useLocation } from "react-router-dom";

import { AuthContext } from "../components/AuthProvider";
import { Header } from "../components/Header";
import { PageName, ROW_MAX_WIDTH } from "../consts";
import { HomeRoutes } from "./HomeRoutes";
import { Loading } from "./Loading";

const { Content } = Layout;

export const Home: React.FC = () => {
  const { guardian } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();

  const [isLoadingContext, setIsLoadingContext] = useState<boolean>(true);

  const { isMobile } = useResponsive();

  useEffect(() => {
    ReactGA.event("page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);

  useEffect(() => {
    if (!_.isEmpty(guardian)) {
      setIsLoadingContext(false);
    }

    if (guardian?.tempPassword !== undefined && guardian?.tempPassword) {
      history.push("/set-password");
    }
  }, [guardian]);

  return (
    <Layout style={{ height: "100%", minHeight: "100vh" }}>
      <Header />
      <Layout>
        <Layout
          style={{
            padding: 10,
            overflow: "scroll",
          }}
        >
          <Content
            style={{
              minHeight: "fit-content",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Row
              style={{
                width: "100%",
                maxWidth: ROW_MAX_WIDTH,
                marginTop: isMobile ? 10 : 30,
              }}
              justify="center"
            >
              {isLoadingContext ? <Loading pageName={PageName.HOME} /> : <HomeRoutes />}
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
