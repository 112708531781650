import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { IUserAvailability } from "../types";

const c = initContract();
export const userAvailabilitiesContract = c.router(
  {
    getLatestByUserId: {
      method: "GET",
      path: "/availabilities",
      pathParams: z.object({
        userId: z.string(),
      }),
      query: z.object({
        clinicId: z.string(),
      }),
      responses: {
        // TODO: Convert to zod schema
        200: c.type<IUserAvailability>(),
        500: z.object({
          message: z.string(),
        }),
      },
    },
  },
  {
    pathPrefix: "/users/:userId",
  }
);
