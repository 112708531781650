import { Button, Col, Form, Input, message, Row, Typography } from "antd";
import { getAuth, updatePassword } from "firebase/auth";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { AuthContext } from "../components/AuthProvider";
import { PageName, VALID_PASSWORD_REGEX } from "../consts";
import { usePageViewTracking } from "../hooks/usePageViewTracking";
import app from "../services/firebase";
import { updateGuardian } from "../services/firestore";
import { Loading } from "./Loading";

const { Title, Text } = Typography;

interface ISetPasswordFormValues {
  password: string;
}

export const SetPassword: React.FC = () => {
  const { guardian } = useContext(AuthContext);
  const history = useHistory();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoadingContext, setIsLoadingContext] = useState<boolean>(true);

  usePageViewTracking(PageName.SET_PASSWORD);

  useEffect(() => {
    if (!_.isEmpty(guardian)) {
      setIsLoadingContext(false);
    }

    if (guardian.tempPassword !== undefined && !guardian.tempPassword) {
      history.push("/");
    }
  }, [guardian]);

  const setPassword = (values: ISetPasswordFormValues) => {
    setIsSaving(true);
    const { password } = values;

    const auth = getAuth(app);
    const user = auth.currentUser;

    if (user === null) {
      void message.error("Oops! Something went wrong, please try again.");
      setIsSaving(false);
      return;
    }

    updatePassword(user, password)
      .then(async () => {
        await updateGuardian(guardian.id, {
          tempPassword: false,
        });
        history.push("/");
        setIsSaving(false);
      })
      .catch(() => {
        void message.error("Oops! Something went wrong, please try again.");
        setIsSaving(false);
      });
  };

  return isLoadingContext ? (
    <Loading pageName={PageName.SET_PASSWORD} />
  ) : (
    <Row style={{ height: "100%", overflow: "hidden" }} justify="center">
      <Row justify="center" align="middle" style={{ textAlign: "center" }}>
        <Col style={{ marginTop: 130 }}>
          <Title style={{ fontFamily: "Reckless Neue" }}>Welcome to Finni</Title>
          <Text
            style={{
              display: "block",
              marginBottom: 30,
              marginRight: "auto",
              marginLeft: "auto",
              fontWeight: 400,
              maxWidth: 530,
            }}
          >
            First, lets set your new password.
          </Text>
          <Form layout="vertical" labelCol={{ span: 24 }} onFinish={setPassword}>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  pattern: VALID_PASSWORD_REGEX,
                  message:
                    "Your Password must be at least 8 characters long, contain at least 1 number, and at least 1 special character (`~!@#$%^&*-_=+;:'\",<.>?)",
                },
                {
                  required: true,
                  message: "Please input your Password",
                },
              ]}
              style={{
                maxWidth: 350,
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 25,
              }}
            >
              <Input.Password
                style={{
                  borderRadius: 50,
                  padding: 10,
                  paddingLeft: 20,
                }}
                name="password"
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your Password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords that you entered do not match")
                    );
                  },
                }),
              ]}
              style={{
                maxWidth: 350,
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 25,
              }}
            >
              <Input.Password
                style={{
                  borderRadius: 50,
                  padding: 10,
                  paddingLeft: 20,
                }}
              />
            </Form.Item>
            <Row justify="center" align="middle" style={{ width: "100%" }}>
              <Form.Item
                style={{
                  width: "50%",
                  minWidth: 125,
                  marginBottom: 0,
                }}
              >
                <Button
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  loading={isSaving}
                  style={{ width: "100%" }}
                >
                  Set Password
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Col>
      </Row>
    </Row>
  );
};
